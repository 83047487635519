<template>
  <div class="perfil-corretora">
    <div class="breadcrumb mb-6">
      <breadcrumb title="Corretor" actualPage="Detalhe Corretor" />
    </div>

    <div class="w-full content p-4 perfil-corretora-tabs" v-if="!!cadastro.Id">
      <vs-tabs color="warning">
        <template
          v-if="
            profileHelpers.checkPermission([
              profileHelpers.menuFuncionalitiesEnum.GERENCIAR_CORRETORA,
              profileHelpers.menuFuncionalitiesEnum.VISUALIZAR_CORRETORA
            ])
          "
        >
          <vs-tab label="Dados Gerais" class="p-4">
            <dados-cadastrais :corretor="cadastro" />
          </vs-tab>
          <vs-tab label="Dados Financeiros">
            <dados-bancarios
              :cadastro="cadastro"
              @updateFinancialData="updateFinancialData"
            />
          </vs-tab>
          <vs-tab label="Sócios/ Administradores PEP">
            <dados-pep :QSAs="socios.lista" :cadastro="cadastro" />
          </vs-tab>
        </template>
        <vs-tab
          label="Dados Usuários"
          v-if="
            profileHelpers.checkPermission([
              profileHelpers.menuFuncionalitiesEnum.VISUALIZAR_USUARIOS,
              profileHelpers.menuFuncionalitiesEnum.GERENCIAR_USUARIOS
            ])
          "
        >
          <dados-usuarios
            :cadastro="cadastro"
            :podeGerenciar="
              profileHelpers.checkPermission([
                profileHelpers.menuFuncionalitiesEnum.GERENCIAR_USUARIOS
              ])
            "
          />
        </vs-tab>
        <vs-tab
          label="Documentos"
          v-if="
            profileHelpers.checkPermission([
              profileHelpers.menuFuncionalitiesEnum.VISUALIZAR_DOCUMENTOS
            ])
          "
        >
          <documentos />
        </vs-tab>
      </vs-tabs>
    </div>
  </div>
</template>
<script>
import DadosCadastrais from "@/components/corretor/DadosCadastrais.vue";
import DadosBancarios from "@/components/corretor/DadosBancariosComponent";
import DadosUsuarios from "@/views/usuarios/ListarUsuario.vue";
import DadosPep from "./components/DadosPep.vue";
import Documentos from "./components/Documentos.vue";
import Breadcrumb from "@/layouts/components/breadcrumb/Breadcrumb.vue";
import PerfilHelpers from "@/helpers/PerfilHelpers";

import * as Utils from "@/utils/utils";

import { mask } from "vue-the-mask";

import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  components: {
    DadosCadastrais,
    DadosBancarios,
    DadosPep,
    DadosUsuarios,
    Documentos,
    Breadcrumb
  },
  directives: { mask },  
  data() {
    return {
      tabActive: "",
      socios: {},
      usuarios: {},
      bancos: [],
      cadastro: {
        SelfDeclaration: false,
        SUSEPProtocol: "",
        SUSEPReregister: false,
        SUSEPProducts: "",
        Name: "",
        NameSusep: "",
        FantasyName: "",
        CpfCnpj: "",
        Email: "",
        LegalNatureCode: "",
        LocationNeighborhood: "",
        LocationStateProvinceName: "",
        LocationStateProvinceAbbreviation: "",
        LocationStateProvinceRegion: "",
        LocationStateProvinceId: 0,
        LocationStreet: "",
        LocationCityName: "",
        LocationCityId: "",
        ZipCode: "",
        PhoneNumber: "",
        BankId: 0,
        BankBranchNumber: "",
        BankAccountNumber: "",
        BankAccountDigit: "",
        Administrator: {
          Name: "",
          Email: ""
        },
        FinancialOfficer: {
          Name: "",
          Email: "",
          CpfCnpj: "",
          PhoneNumber: ""
        }
      },

      profileHelpers: PerfilHelpers
    };
  },
  async mounted() {
    await this.carregarCorretora();
  },
  created() {
    this.$appInsights.trackPageView({
      name: "corretor-perfil",
      url: window.location.href
    });
  },
  computed: {
    ...mapGetters("cadastro-corretora", ["corretora"])
  },
  methods: {
    ...mapActions("cadastro-corretora", ["getCorretora", "atualizarCorretora"]),
    ...mapMutations("cadastro-corretora", ["updateCorretora"]),

    updateFinancialData() {
      this.carregarCorretora();
    },

    formSubmitted() {
      this.$onpoint.loadingModal(
        "Aguarde, sua solicitação está sendo processada",
        () => {
          return this.atualizarCorretora(this.cadastro)
            .then(response => {
              if (response.registerAffecteds) {
                this.$onpoint.successModal("Perfil atualizado com sucesso.");
              }
              setTimeout(() => {
                this.carregarCorretora();
              }, 500);
            })
            .catch(errors => {
              this.$onpoint.errorModal(errors.response.data.Errors);
            });
        }
      );
    },

    carregarCorretora() {
      this.$onpoint.loadingModal(
        "Aguarde, carregando informações da corretora",
        () => {
          return this.getCorretora()
            .then(response => {
              this.cadastro = Utils.deepClone(response);
              this.$set(this.socios, "lista", response.QSAs);
            })
            .catch(errors => {
              this.$onpoint.errorModal(errors.response.data.Errors);
            });
        }
      );
    }
  }
};
</script>
<style lang="scss">
.perfil-corretora-tabs {
  background: white;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.vs-tabs--ul {
  border-bottom: 2px solid #d9d9d9 !important;
  box-shadow: none;
}
</style>
